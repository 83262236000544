import React from "react"

import Layout from "components/Layout"
import SubpageHero from "components/SubpageHero"
import Breadcrumbs from "components/Breadcrumbs"

import { Content, Baner } from "page_components/career"

const Career = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Kariera",
    }}
  >
    <SubpageHero title="Kariera" />
    <Breadcrumbs title="Kariera" />
    <Content />
    <Baner />
  </Layout>
)

export default Career
